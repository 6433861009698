import BubbleSvg from "~/assets/bubble-white.svg";
// import { Breakpoints, useView } from "~/utils/useView";
import { type RefObject, useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

interface BubbleProps {
  numbering: number;
  title: string;
  body: string;
  className?: string;
}

export default function BubbleWhite({
  numbering,
  title,
  body,
  className,
}: BubbleProps) {
  // const isMobileView = useView(Breakpoints.Mobile);
  const ref = useRef<HTMLDivElement>();
  const bubble = useAnimation();

  const inView = useInView(ref as RefObject<HTMLDivElement>, {
    once: true,
    amount: 0.5,
  });

  useEffect(() => {
    if (inView) {
      void bubble.start({ opacity: 1 }).catch();
    }
  }, [bubble, inView]);

  return (
    <motion.div
      ref={ref as RefObject<HTMLDivElement>}
      initial={{ opacity: 0 }}
      animate={bubble}
      className={className}
    >
      <div className="relative w-full">
        <div
          className="absolute
            z-10
            inline-flex h-[4.5rem]
            w-[4.5rem] items-center justify-center
            bg-brand-green-primary sm:top-0"
        >
          <span className="title-xx-large z-10 text-bg-primary">
            {numbering}
          </span>
        </div>
        <div className="absolute left-12 top-28 z-10 w-9/12 sm:left-20 sm:top-24 md:w-8/12 lg:left-16 xl:left-14 xl:w-9/12">
          <span className="title-xx-large mx-auto text-bg-primary">
            {title}
          </span>
        </div>
        <div className="absolute left-12 top-52 z-10 w-9/12 sm:left-20 sm:top-48 md:w-8/12 lg:left-16 xl:left-14 xl:w-9/12">
          <span className="body-large text-bg-secondary">{body}</span>
        </div>
        <div
          className={`relative
        shadow-[0_-4px_0_#213842] sm:shadow-[-4px_-4px_0_#213842]
        xl:shadow-none z-${numbering + 20}`}
        >
          <BubbleSvg />
        </div>
      </div>
    </motion.div>
  );
}
