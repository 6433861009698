import Head from "next/head";
import Background from "~/assets/bg.svg";
import BackgroundTablet from "~/assets/bg-tablet.svg";
import BackgroundMobile from "~/assets/bg-mobile.svg";
import BubbleWhite from "~/components/BubbleWhite";
import { useTranslation } from "react-i18next";
import "~/i18n/i18n";
import { Header } from "~/sections/Header";
import { HeroSection } from "~/sections/HeroSection";
import { HelpSection } from "~/sections/HelpSection";
import { Footer } from "~/sections/Footer";

export default function Home() {
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <title>Rigo</title>
      </Head>

      <Header />

      <Background className="absolute hidden w-full xl:block" />
      <BackgroundTablet className="absolute hidden sm:block sm:w-full xl:hidden" />
      <BackgroundMobile className="absolute block w-full sm:hidden" />

      <main className="relative mx-auto flex flex-col pt-28 md:pt-40 xl:max-w-[1200px] xl:pt-28">
        <HeroSection />

        <section
          className="mt-28 h-[1020px] w-full sm:h-[1100px] sm:w-10/12 md:mx-auto xl:mx-0 xl:mt-64 xl:flex xl:h-[800px] xl:gap-12"
          id="jak-to-funguje"
        >
          <div className="translate-y-0">
            <BubbleWhite
              numbering={1}
              title={t("first-bubble.title")}
              body={t("first-bubble.content")}
              className="absolute left-0 right-0 mx-auto w-fit md:mx-0 xl:relative"
            />
          </div>

          <div className="translate-y-80 xl:translate-y-48">
            <BubbleWhite
              numbering={2}
              title={t("second-bubble.title")}
              body={t("second-bubble.content")}
              className="absolute left-0 right-0 mx-auto w-fit sm:left-36 md:mx-0 lg:left-[16.75rem] xl:relative xl:left-0"
            />
          </div>
          <div className="translate-y-96">
            <BubbleWhite
              numbering={3}
              title={t("third-bubble.title")}
              body={t("third-bubble.content")}
              className="absolute left-0 right-0 top-64 mx-auto w-fit sm:left-72 md:mx-0 lg:left-[33.5rem] xl:relative xl:left-0 xl:top-0"
            />
          </div>
        </section>

        <HelpSection />
      </main>
      <Footer />
    </>
  );
}
